<template>
	<div id="provider" class="pink lighten-5">
		<h2 class="text-titulo section-title">{{ $store.state.titulofix }}</h2>
		<v-spacer class="my-3"></v-spacer>
		<v-container fluid>
			<v-row dense>
				<v-col cols="12" md="auto">
					<v-btn
						@click="fnReload"
						color="grey darken-3"
						class="my-2"
						large
						dark>
						<v-icon left>mdi-reload</v-icon>Reload
					</v-btn>
				</v-col>
				<v-col cols="12" md="2">
					<v-text-field 
						label="Filter Order" 
						prepend-icon="mdi-magnify"
						v-model="buscador"
						v-on:keyup.enter="fnLoader('search', buscador)"
						clearable
					>
					</v-text-field>
				</v-col>
				<v-col cols="12" md="auto">
					<v-dialog
						v-model="stDlgPart"
						persistent
						max-width="600">
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="ma-1" large color="blue lighten-1" v-bind="attrs" v-on="on" :disabled="selected.length == 0">
								<v-icon left>mdi-exit-to-app</v-icon>partial
							</v-btn>
						</template>
						<v-card>
							<v-card-title class="headline">
								Warning
							</v-card-title>
							<v-card-text>Do you really want to send this {{ selected.length }} order/s?</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" text @click="stDlgPart = false"><v-icon left>mdi-close</v-icon>Cancel</v-btn>
								<v-btn color="success" text @click="fnSendPrtl"><v-icon left>mdi-check</v-icon>Ok</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
				<v-col md="auto">
					<v-dialog
						v-model="stDlgFull"
						persistent
						max-width="600">
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="ma-1" large color="green lighten-1" v-bind="attrs" v-on="on" :disabled="fnCounter(0) == 0">
								<v-icon left>mdi-exit-to-app</v-icon>all
							</v-btn>
						</template>
						<v-card>
							<v-card-title class="headline">
								Warning
							</v-card-title>
							<v-card-text>Do you really want to send this {{ fnCounter(5) }} order/s?</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" text @click="stDlgFull = false"><v-icon left>mdi-close</v-icon>Cancel</v-btn>
								<v-btn color="success" text @click="fnSendAll"><v-icon left>mdi-check</v-icon>Ok</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
				<v-col md="auto">
					<v-dialog
						v-model="stClsAll"
						persistent
						max-width="600">
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="ma-1" large color="orange lighten-3" v-bind="attrs" v-on="on" :disabled="fnCounter(10) == 0">
								<v-icon left>mdi-close-box-outline</v-icon>close
							</v-btn>
						</template>
						<v-card>
							<v-card-title class="headline">
								Warning
							</v-card-title>
							<v-card-text>Do you really want to close all this {{ fnCounter(10) }} order/s?</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="error" text @click="stClsAll = false"><v-icon left>mdi-close</v-icon>Cancel</v-btn>
								<v-btn color="success" text @click="fnCloseAll"><v-icon left>mdi-check</v-icon>Ok</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-col>
				<v-spacer></v-spacer>
				<v-col md="auto">
					<v-btn @click="stFiltro = -1" color="orange darken-3" class="my-2" :disabled="stFiltro == -1" outlined large><v-icon left>mdi-compass</v-icon>Clear</v-btn>
				</v-col>
				<v-col md="auto">
					<v-badge color="blue-grey darken-2" bordered overlap :content="fnCounter(0)" :value="fnCounter(0)">
						<v-btn @click="stFiltro = 0" color="grey lighten-2" class="my-2" large elevation="0">Open</v-btn>
					</v-badge>
				</v-col>
				<v-col md="auto">
					<v-badge color="blue-grey darken-2" bordered overlap :content="fnCounter(8)" :value="fnCounter(8)">
						<v-btn @click="stFiltro = 8" color="blue-grey lighten-2" class="my-2" large elevation="0">Modified</v-btn>
					</v-badge>
				</v-col>
				<v-col md="auto">
					<v-badge color="blue-grey darken-2" bordered overlap :content="fnCounter(3)" :value="fnCounter(3)">
						<v-btn @click="stFiltro = 3" color="light-blue lighten-2" class="my-2" large elevation="0">Holded</v-btn>
					</v-badge>
				</v-col>
				<v-col md="auto">
					<v-badge color="blue-grey darken-2" bordered overlap :content="fnCounter(15)" :value="fnCounter(15)">
						<v-btn @click="stFiltro = 15" color="red lighten-2" class="my-2" large elevation="0">Error</v-btn>
					</v-badge>
				</v-col>
			</v-row>
		</v-container>
		<!-- tabla -->
		<v-data-table 
			:headers="cabecera"
			:items="listado"
			v-model="selected"
			show-select
			:loading="stLoader"
			:search="buscador"
			:item-class="fnLineclass"
			:items-per-page="limite"
			:sort-by="['createdAt']"
			:sort-desc="true"
			:hide-default-footer="true"
			:show-expand="true"
			:expanded="abiertos">
			<!-- Slots -->
			<template v-slot:[`item.customer`]="{ item }">
				<v-edit-dialog
					:return-value.sync="item.customer"
					large
					persistent
					@save="fnUpdateCus(item.orderno, item.customer)">
						<div>{{ item.customer }}</div>
						<template v-slot:input>
							<v-text-field
								v-model="item.customer"
								label="Edit"
								single-line
								counter
								autofocus
								style="width: 450px;"
								></v-text-field>
						</template>
				</v-edit-dialog>
			</template>
			<template v-slot:[`item.shipping`]="{ item }">
				<v-edit-dialog
					:return-value.sync="item.shipping"
					large
					persistent
					@save="fnUpdateShp(item.orderno, item.shipping)">
					<div>{{ item.shipping }}</div>
						<template v-slot:input>
							<v-text-field
								v-model="item.shipping"
								label="Edit"
								single-line
								counter
								autofocus
								style="width: 450px;"
								></v-text-field>
						</template>
				</v-edit-dialog>
			</template>
			<template v-slot:[`item.createdAt`]="{ item }">
				{{ fnTimeZone(item.createdAt) }}
			</template>
			<template v-slot:[`item.modiDate`]="{ item }">
				{{ fnTimeZone(item.modiDate) }}
			</template>
			<template v-slot:[`item.status`]="{ item }">
				{{ fnStatus(item.status) }}
			</template>
			<template v-slot:[`item.tools`]="{ item }">
				<v-btn :disabled="item.status == 10" @click="fnEdit(item)" icon color="grey darken-1"><v-icon>mdi-pencil</v-icon></v-btn>
				<v-btn v-if="item.status != 3 && item.status != 8 && item.status != 15" :disabled="item.status == 10" @click="fnHold(item.orderno)" icon color="grey darken-1"><v-icon>mdi-pause</v-icon></v-btn>
				<v-btn v-else dark icon @click="fnPlay(item.orderno)" color="grey darken-1"><v-icon>mdi-play</v-icon></v-btn>
				<v-btn dark icon color="grey darken-1" @click.stop="$set(stDlgClose, item.id, true)"><v-icon>mdi-window-close</v-icon></v-btn>
				<!-- Dynamic Dialog -->
				<v-dialog
					v-model="stDlgClose[item.id]"
					key="item.id"
					persistent
					max-width="600">
					<v-card>
						<v-card-title class="headline">
							Warning
						</v-card-title>
						<v-card-text>Do you really want to close the order {{ item.orderno }}?</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="error" text @click.stop="$set(stDlgClose, item.id, false)"><v-icon left>mdi-close</v-icon>Cancel</v-btn>
							<v-btn color="success" text @click="fnDlgCls(item.orderno)"><v-icon left>mdi-check</v-icon>Ok</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</template>

			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" style="padding:0;">
					<v-card class="pa-4 grey darken-2" flat>
						<v-simple-table style="width: 80%" class="mx-auto" dense>
							<template v-slot:default>
								<thead class="grey lighten-1">
									<tr>
										<th>sku</th>
										<th>product</th>
										<th>variant</th>
										<th>quantity</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="linea in item.items" :key="linea.sku">
										<td>{{ linea.sku }}</td>
										<td>{{ linea.name }}</td>
										<td>{{ linea.variant }}</td>
										<td>{{ linea.qty }}</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
						<v-card style="width: 80%" class="mx-auto pa-5 mt-1" outlined>
							<h3 class="text-md-h6">Logs</h3>
							<ul>
								<li v-for="mensaje in item.log" :key="mensaje.fecha">{{ mensaje.desc }}</li>
							</ul>
						</v-card>
					</v-card>
				</td>
			</template>

			<!-- Paginado personalziado -->
			<template v-slot:footer>
				<v-btn 
					block 
					text 
					x-large 
					color="deep-orange darken-3" 
					@click="fnLoader" 
					:disabled="stNext" 
					:loading="stLoader"><v-icon left>mdi-chevron-double-down</v-icon>Load more<v-icon right>mdi-chevron-double-down</v-icon></v-btn>
			</template>
		</v-data-table>

		<!-- Editor -->
		<v-dialog v-model="wnForm" persistent max-width="900px">
			<v-card>
				<v-card-title>
					<span class="headline">ORDER EDITOR</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-autocomplete
							v-model="miProduct"
							:items="autoItems"
							:loading="autoLoad"
							:search-input.sync="fnSearchProd"
							hide-no-data
							hide-selected
							placeholder="Start typing to search a product to insert"
							prepend-icon="mdi-database-search">
						</v-autocomplete>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>SKU</th>
										<th>product</th>
										<th>variant</th>
										<th>quantity</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, idx) in sublista" :key="idx">
										<td>{{ item.sku }}</td>
										<td>{{ item.name }}</td>
										<td class="text-center">
											<v-select
												:items="item.opcions"
												v-model="item.sku"
												:item-text="fnSelTxt"
												item-value="sku"
												dense
												v-on:keyup.enter="fnChgOpt(idx, item.sku)"
												class="text-right"></v-select>
										</td>
										<td class="text-center">
											<v-text-field
												v-model="item.qty"
												style="max-width:60px"></v-text-field>
										</td>
										<td class="text-center"><v-btn small depressed icon @click="fnRemove(idx)"><v-icon>mdi-delete</v-icon></v-btn></td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text @click="wnForm = false">Close<v-icon right>mdi-close-circle</v-icon></v-btn>
					<v-btn text @click="fnSaveMe()">Save<v-icon right>mdi-content-save</v-icon></v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import axios from "axios"
import { DateTime } from "luxon"

export default {
	name: 'SEProvider',
	metaInfo: {
		title: 'SE Provider'
	},
	data() {
		return {
			stLoader: false,
			abiertos: [],
			listado: [],
			buscador: null,
			stDlgPart: false,
			stDlgFull: false,
			stClsAll:false,
			stFiltro: -1,
			limite: -1,
			stNext: true,
			wnForm: false,
			miProduct: null,
			fnSearchProd: null,
			autoItems: [],
			autoLoad: false,
			edonro: null,
			stCurEdIdx: null,
			sublista: [],
			selected: [],
			stDlgClose: {}
		}
	},
	mounted() {
		this.fnLoader()
	},
	methods: {
		fnLoader() {
			this.stLoader = true
			this.abiertos = []
			axios.post('/se-vpmorders', { cursor: this.cursor })
				.then(response => {
					this.listado = response.data.list
					this.stNext = !response.data.navi.next
					this.cursor = response.data.navi.cursor
					this.stLoader = false
				})
			this.$store.state.titulofix = 'Request to vpm from STEVEN'
		},
		fnReload() {
			this.stLoader = true
			this.abiertos = []
			axios.post('/se-vpmreload')
				.then(response => {
					this.listado = response.data
					this.stLoader = false
				})
		},
		fnSendAll() {
			axios.post('/se-sndallvpm')
				.then(() => {
					this.$store.state.mensaje = 'All orders was marked as sended!'
					this.$store.state.estado = 'success'
					this.$store.state.snackme = true
					this.$successsnd()
					this.stDlgFull = false
				})
		},
		fnSendPrtl() {
			const ids = JSON.stringify(this.selected.map( el => el.orderno ))
			axios.post('/se-sndprtvpm', {ids: ids})
				.then(() => {
					this.$store.state.mensaje = 'Selected orders was marked as sended!'
					this.$store.state.estado = 'success'
					this.$store.state.snackme = true
					this.$successsnd()
					this.stDlgPart = false
				})
		},
		fnUpdateCus(oid, customer) {
			axios.post('/se-updcus', {oid: oid, customer: customer})
				.then(() => {
					this.$store.state.mensaje = 'The customer name was updated!'
					this.$store.state.estado = 'success'
					this.$store.state.snackme = true
					this.$successsnd()
				})
		},
		fnUpdateShp(oid, ship) {
			axios.post('/se-updshp', {oid: oid, ship: ship})
				.then(() => {
					this.$store.state.mensaje = 'The shipping was updated!'
					this.$store.state.estado = 'success'
					this.$store.state.snackme = true
					this.$successsnd()
				})
		},
		fnCloseAll() {
			axios.post('/se-clsallvpm')
				.then(() => {
					this.$store.state.mensaje = 'All processed orders was closed!'
					this.$store.state.estado = 'success'
					this.$store.state.snackme = true
					this.$successsnd()
					this.fnLoader()
					this.stClsAll = false
				})
		},
		fnHold(oid) {
			axios.post('/se-setflag', { id: oid, flag: '3'})
				.then(response => {
					if(response.data) {
						this.$store.state.mensaje = 'This order was put in HOLD'
						this.$store.state.estado = 'success'
						this.$store.state.snackme = true
						this.$successsnd()
						this.fnReload()
					}else{
						this.$store.state.mensaje = 'Something go wrong, please try later...'
						this.$store.state.estado = 'error'
						this.$store.state.snackme = true
						this.$errorsnd()
						this.fnReload()
					}
					this.stClsAll = false
				})
		},
		fnPlay(oid) {
			axios.post('/se-setflag', { id: oid, flag: '5'})
				.then(response => {
					if(response.data) {
						this.$store.state.mensaje = 'This order was put in PENDIDNG'
						this.$store.state.estado = 'success'
						this.$store.state.snackme = true
						this.$successsnd()
						this.fnReload()
					}else{
						this.$store.state.mensaje = 'Something go wrong, please try later...'
						this.$store.state.estado = 'error'
						this.$store.state.snackme = true
						this.$errorsnd()
						this.fnReload()
					}
					this.stClsAll = false
				})
		},
		fnDlgCls(oid) {
			axios.post('/se-setflag', { id: oid, flag: '100'})
				.then(response => {
					if(response.data) {
						this.$store.state.mensaje = 'This order is CLOSED'
						this.$store.state.estado = 'success'
						this.$store.state.snackme = true
						this.$successsnd()
						this.fnReload()
					}else{
						this.$store.state.mensaje = 'Something go wrong, please try later...'
						this.$store.state.estado = 'error'
						this.$store.state.snackme = true
						this.$errorsnd()
						this.fnReload()
					}
					this.stClsAll = false
				})
		},
		fnEdit(obj) {
			this.abiertos = []
			this.sublista = []
			this.autoItems = []
			this.autolista = []
			this.autoLoad = false
			this.edonro = null
			this.stCurEdIdx = this.listado.findIndex(el => el.id == obj.id)
			var temp = []
			obj.items.forEach(function (item) {
				axios.post('/se-getvarbyid', { id: item.pid })
					.then(response => {
						temp.push({ 'pid': item.pid, 'sku': item.sku, 'name': item.name, 'variant': item.variant, 'qty': item.qty, 'opcions': response.data })
					})
			}) 
			this.sublista = temp
			this.edonro = obj.orderno
			this.wnForm = true
		},
		fnSaveMe() {
			axios.post('/se-sveorder', {  id: this.edonro, details: this.sublista })
				.then(response => {
					console.info(response)
					this.wnForm = false
				})
			this.fnReload()
		},
		fnRemove(idx) {
			this.sublista.splice(idx, 1)
		},
		fnChgOpt(idx, sku) {
			let size = this.sublista[idx].opcions.find( e => e.sku == sku)
			this.sublista[idx].variant = size.title
		},
		fnSelTxt: item => {
			if(item.stock) {
				return item.title + " (" + item.stock + ")"
			}else{
				return item.title
			}
		},
		fnLineclass(valor) {
			let bgColor = ''
			switch(valor.status) {
				case 0:
					bgColor = ''
					break
				case 3:
					bgColor = 'light-blue lighten-4'
					break
				case 5:
				case 7:
					bgColor = 'brown lighten-5'
					break
				case 8:
					bgColor = 'amber accent-4'
					break
				case 10:
					bgColor = 'light-green lighten-4'
					break
				case 15:
					bgColor = 'red lighten-4'
					break
				default:
					bgColor = ''
			}
			return bgColor
		},
		fnStatus(valor) {
			let texto = ''
			switch(valor) {
				case 0:
					texto = 'OPEN'
					break
				case 3:
					texto = 'HOLD'
					break
				case 5:
				case 7:
					texto = 'PENDING'
					break
				case 8:
					texto = 'MODIFIED'
					break
				case 10:
					texto = 'PROCESSED'
					break
				case 15:
					texto = 'ERROR'
					break
				case 20:
					texto = 'SHIPPED'
					break
				default:
					texto = 'N/D'
			}
			return texto
		},
		fnTimeZone(val) {
			if(val != '') {
				const fecha = DateTime.fromISO(val, { zone: "America/New_York" })
				return fecha.toLocaleString(DateTime.DATETIME_MED)
			}else{
				return 'N/A'
			}
		},
		fnCounter(valor) {
			let contador = 0
			this.listado.forEach(function(itm) {
				if(itm.status == valor) contador++
			})
			return contador
		}
	},
	computed: {
		cabecera() {
			return [
				{ text: 'Order no', value: 'orderno' },
				{ text: 'Customer', value: 'customer' },
				{ text: 'Shipping', value: 'shipping' },
				{ text: 'Items', value: 'quantity', align: 'center', filterable: false },
				{ text: 'Purchased', value: 'createdAt', align: 'end', filterable: false },
				{ text: 'Date log', value: 'modiDate', align: 'end', filterable: false },
				{ text: 'Status', value: 'status', align: 'center', filter: value => { return (this.stFiltro == -1)?true:(value == this.stFiltro) } },
				{ text: 'Tools', value: 'tools', align: 'center', width: '150px', filterable: false }
			]
		}
	},
	watch: {
		fnSearchProd(val) {
			if(!val || val.length < 4) {
				return
			}
			if(this.autoLoad) return
			this.autoLoad = true
			this.autoItems = []
			this.autolista = []
			axios.post('/se-srchprod', { word: val })
				.then(response => {
					var temp = []
					response.data.forEach(item => {
						temp.push(item.name)
					})
					console.log(temp)
					this.autoItems = temp
					this.autolista = response.data
				})
				.catch(() => {
					this.autoItems = []
					this.autolista = []
				})
				.finally(() => {
					this.autoLoad = false
				})
		},
		miProduct: function(val) {
			let resultado = this.autolista.find( elem => elem.name == val )
			this.sublista.push(resultado)
		}
	}
}
</script>